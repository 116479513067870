

/*** Footer ***/
.footer {
    background: #052822;
    background-size: cover;
    overflow: hidden;
}

.bg-dark {
    background: #052822 !important;
}

.footer .btn.btn-social {
    margin-right: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--light);
    border: 1px solid #FFFFFF;
    transition: .3s;
}

.footer .btn.btn-social:hover {
    color: #EEEEEE;
}

.footer .btn.btn-link {
    display: block;
    margin-bottom: 5px;
    padding: 0;
    text-align: left;
    color: #BBBBBB;
    font-size: 16px;
    font-weight: normal;
    text-transform: capitalize;
    transition: .3s;
}

.footer .btn.btn-link::before {
    position: relative;
    font-family: 'Euclid Square', serif !important;
    font-weight: 900;
    margin-right: 10px;
}

.footer .btn.btn-link:hover {
    color: #EEEEEE;
    box-shadow: none;
}

@media (max-width: 768px) {
    .footer .copyright {
        padding: 3em 0px;
        font-size: 15px;
    }

    .form-field {
        width: 65% !important;
    }
}

.footer .copyright {
    padding: 0px 0px 3em 0px;
    font-size: 15px;
}

.footer .copyright a {
    color: #BBBBBB;
}

.footer .copyright a:hover {
    color: #EEEEEE;
}

.footer .margin-right {
    margin-right: 20px;
    color: #BBBBBB !important;
    /* font-size: 14px !important; */
}

.form-field {
    color: #FFFFFF;
    font-family: inherit;
    width: 85%;
    border: 0;
    border-bottom: 1px solid #FFFFFF;
    outline: 0;
    font-size: 0.813rem;
    padding: 7px 0;
    background: transparent;
    transition: border-color 0.2s;
}

.form-label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: #FFFFFF;
}

.footer .footer-bottom-header a {
    font-size: 12px;
}

.mx-auto {
    float: left;
}

.white-line {
    border-bottom: 1px solid #FFFFFF;
    float: left;
    color: #FFFFFF;
}

.logo-gap {
    margin-bottom: 20px;
}

.text-light {
    font-size: 25px !important;
    line-height: 1.3em;
    font-weight: 400;
}

.text-light p {
    font-size: 15px !important;
    color: #BBBBBB;
}


button:not(:disabled),
[type="button"]:not(:disabled) {
    text-align: right;
}

.border-1 {
    border-width: 0px !important;
}

.border-0 {
    border-width: 0px !important;
}

