/* @import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@1&family=Poppins:wght@400;700&family=Raleway:wght@700&display=swap'); */



:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  --primary-color: #003C32;
  --primary-color-dark: #052822;
  --primary-color-light: #659254;
  --secondary-color: #839CA3;
  --black-color: #1E1E1E;
  --gray-color-dark: #262626;
  --gray-color: #383838;
  --gray-color-light: #666666;
  font-family: 'Poppins', sans-serif;
}

@font-face {
  font-family: 'Poppins';
  src: url('../../assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-style: normal;
}

body {
  line-height: 1.35 !important;
  font-family: 'Poppins', sans-serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}
video {
  width: 100%;
  height: auto;
}
.font-raleway {
  font-family: 'Raleway', sans-serif;
}
.font-Poppins {
  font-family: 'Poppins', sans-serif;
}
.primary-color {
  color: var(--primary-color);
}
.primary-color-dark {
  color: var(--primary-color-dark);
}
.primary-color-light {
  color: var(--primary-color-light);
}
.secondary-color {
  color: var(--secondary-color);
}

.bg-primary-color {
  background-color: var(--primary-color);
}
.bg-primary-color-dark {
  background-color: var(--primary-color-dark);
}
.bg-primary-color-light {
  background-color: var(--primary-color-light);
}
.bg-secondary-color {
  background-color: var(--secondary-color);
}

.gray-color {
  color: var(--gray-color);
}
.gray-color-dark {
  color: var(--gray-color-dark);
}
.gray-color-light {
  color: var(--gray-color-light);
}

.bg-gray-color {
  background-color: var(--gray-color);
}
.bg-gray-color-dark {
  background-color: var(--gray-color-dark);
}
.bg-gray-color-light {
  background-color: var(--gray-color-light);
}
.overflow-hidden {
  overflow: hidden;
}
.h-separator {
  display: inline-block;
  background-color: var(--black-color);
  height: 2px;
  overflow: hidden;
  width: 60px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
}

a {
  text-decoration: none !important;
}

.carousel .thumbs-wrapper {
  margin: 11px 0 !important;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem !important;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;

  white-space: nowrap;
  overflow: hidden;
  padding: 2px;
  height: 20px;
  border-radius: 50%;
}

.carousel .thumbs {
  margin: 0;
}

.carousel-root {
  outline: none;
  width: 100%;
  height: 39rem;
}

.carousel-slider{
  height: 100% !important;
}
.carousel .slider-wrapper{
  height: 100% !important;
}

.rpv-core__inner-pages {
  overflow: hidden !important;
}

.pg-viewer-wrapper {
  overflow: hidden !important;
}

.pdf-canvas {
  display: flex !important;
  
}

.pg-viewer-wrapper::-webkit-scrollbar {
  display: none;
}
.rbc-day-slot .rbc-event-label {
  display: none;
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-day-slot .rbc-event-content {
  display: flex;
  align-items: center;
}

.rbc-date-cell {
  display: flex;
  overflow-y: scroll;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
.rbc-event{
  padding: 0 !important;
}

.carousel .slide{

display: flex;
    justify-content: center;
    /* align-items: center; */
    justify-content: center;
}

.carousel .slide img {
  width: 90% !important;
    height: 100% !important;
}

.pdf-container {
  
  display: flex !important;
  justify-content: center !important;
}

.carousel .slide .legend {
  bottom: -40px !important;
  width: 60% !important;
  padding: 5px !important;
  margin-left: -30% !important;
}
/* Styling the container */
.Modal_pdf_canvas {
  /* Add container styles here */
  display: flex !important;
  justify-content: center !important;
  height: 35rem !important;
  width:30rem !important
}