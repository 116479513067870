@import 'calendar.css';
@import 'react-notification.css';
@import 'react-table.css';
@import 'ck-editor.css';
@import 'maps.css';
@import 'timeline.css';
@import 'slider.css';
@import 'chat-window.css';
@import 'material-design-icon/css/material-design-iconic-font.min.css';
@import 'country-flag/sprite-flags-24x24.css';
 

.drop-area {
    border: 2px dashed #ccc;
    border-radius: 5px;
    padding: 20px;
    width: 500px;
    text-align: center;
  }
  
  .drag-over {
    border-color: #007bff; /* Add your preferred color for the drag-over effect */
  }

